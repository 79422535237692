@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;700&family=Ubuntu:wght@300;400;700&display=swap');

:root {
  --primary-1: #282828;
  --primary-2: rgba(13, 15, 69, 1);
  --primary-3: #1761b0;
  --primary-4: #e6e0bb;
  --primary-5: #d2292d;
  --complementary-1: #424242;
  --complementary-2: #00000d;
  --complementary-3: #b07317;
  --complementary-4: #c8bbe6;
  --complementary-5: #2fd229;
  --logo-height: 12rem;
  --theme-radius: 4px;
  --animation-duration: 0.5s;
  --animation-timing-function: ease-in-out;
  --panel-height: 100vh;
}

/* Elements */
body {
  background-color: var(--primary-1);
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  color: var(--primary-4);
  font-size: 1.1rem;
  font-family: 'Ubuntu', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Orbitron', sans-serif;
  font-weight: 500;
  color: var(--primary-4);
}

div.panel-section {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 2rem 1fr;
  grid-template-areas: "heading heading" "image text";
  justify-content: center;
  align-items: center;
  justify-items: center;
  box-sizing: border-box;
  overflow: hidden;
  padding: 4rem;
  z-index: 49;
  min-height: calc(100vh - var(--logo-height));
  translate: 0 0 10%;
}

div.panel-section:nth-of-type(2n) {
  grid-template-columns: 1fr 2fr;
  grid-template-areas:
    "heading heading"
    "text image";
  text-align: right;
}

span.cta {
  text-align: center;
}

button.form-button, span.cta-button {
  display: inline-block;
  padding: 0.5rem 1rem;
  border-radius: var(--theme-radius);
  color: var(--complementary-5);
  border: 1px solid var(--complementary-5);
  font-size: 1.2rem;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;
  z-index: 50;
  background-color: transparent;
}

button.form-button:nth-of-type(1) {
  color: var(--complementary-3);
  border-color: var(--complementary-3);
}

span.cta-button:active, span.cta-button:hover {
  background-color: var(--complementary-5);
  color: var(--primary-1);
  cursor: pointer;
}

div.panel-section:nth-of-type(1)>img {
  right: 0 !important;
}

div.helper-text {
  position: fixed;
  bottom: -5vh;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--primary-4);
  z-index: 60;
  opacity: 0;
}

/* IDs */
#footer {
  display: flex;
  justify-content: center;
  gap: 1rem;
  align-items: flex-end;
  padding: 2rem;
  height: 12rem;
}

#contact-form {
  background-color: var(--complementary-1);
  color: var(--primary-4);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 480px;
  width: 350px;
  border-radius: var(--theme-radius);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1.5rem;
}

#App {
  min-height: 110vh;
}

#App-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(var(--logo-height) - 3rem);
  background-color: var(--primary-1);
  z-index: 50;
}

#screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--primary-1);
  opacity: 0.98;
  z-index: 49;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

#pp-link {
  text-decoration: none;
  color: var(--primary-4);
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

#pp-link:hover {
  color: var(--primary-3);
  text-decoration: underline;
}

/* Classes */
.section-heading {
  grid-area: heading;
  opacity: 0;
}

.section-heading:nth-of-type(1) {
  opacity: 1;
}

.section-content {
  grid-area: text;
  opacity: 0;
}

.section-content:nth-of-type(1) {
  opacity: 1;
}

.section-image {
  grid-area: image;
  max-width: 60vw;
  max-height: 60vh;
  position: relative;
  z-index: 40;
}

.App-logo {
  height: calc(var(--logo-height) * 3);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 50;
}

.App-content {
  padding-top: var(--logo-height);
  position: relative;
  z-index: 30;
  min-height: 100vh;
}

.nav-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 30;
}

.nav-list {
  list-style-type: none;
  display: flex;
  flex: row nowrap;
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;
  padding-right: 3rem;
  position: relative;
  top: -50vh;
}

.nav-link {
  display: inline-block;
  border-radius: var(--theme-radius);
  color: var(--primary-4);
  font-size: 1.2rem;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
  transition: color 0.35s ease-in-out;
  text-decoration: none;
}

.nav-link:hover {
  color: var(--complementary-5);
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: baseline;
  width: 100%;
}

.form-group>input, textarea {
  border-radius: var(--theme-radius);
  padding: 0.25rem;
}

/* Media queries */
@media screen and (max-width: 768px) {

  div.panel-section, div.panel-section:nth-of-type(2n) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "heading"
      "image"
      "text"
    ;
  }

  .nav-list {
    padding: 0 2rem;
    top: -50vh;
  }

  .nav-link {
    font-size: 0.8rem;
  }
}

@media screen and (min-width:1920px) and (max-width: 2559px) {
  .section-heading {
    text-align: center;
  }

  div.panel-section {
    text-align: left;
    grid-template-areas:
      "heading heading"
      "image text";
  }

  div.panel-section.alt {
    text-align: right;
    grid-template-areas:
      "heading heading"
      "text image";
  }
}

@media screen and (min-width:2561px) {
  body {
    font-size: 2rem;
  }

  div.panel-section {
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    grid-template-areas:
      "heading heading"
      "image text";
  }

  div.panel-section.alt {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "heading heading"
      "text image";
  }
}